@use '@on3/styles/variables' as var;

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/on3/theme';

@use 'sass:color';

%join-link {
  width: 75px;

  @media screen and (min-width: var.$tablet-width) {
    width: 90px;
  }

  @media screen and (min-width: var.$desktop-width) {
    width: 75px;
  }

  @media screen and (min-width: var.$full-width) {
    width: 90px;
  }
}

.block {
  @include mix.flex($justify: flex-end, $gap: var.$spacing-sm);

  .search {
    display: none;

    @media only screen and (min-width: var.$small-width) {
      display: flex;
      margin-right: var.$spacing-compact;
    }
  }

  .on3 {
    display: none;

    @media screen and (min-width: var.$small-width) {
      display: flex;
      padding-left: var.$spacing-sm;
      border-left: 1px solid currentcolor;

      svg {
        fill: currentcolor;
      }
    }
  }
}

.login {
  display: none;

  @media only screen and (min-width: var.$desktop-width) {
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1.2;
    letter-spacing: 0.65px;
    color: inherit;
  }
}

.pianoJoin {
  @extend %join-link;

  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  border-radius: var.$radius-sm;
  overflow: hidden;

  div {
    @include mix.flex;
  }
}

.claim {
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
}

.joinLink {
  @extend %join-link;

  @extend %btn;

  border-radius: 2px;
  text-align: center;
  padding: 0.5rem;
  color: var.$night;
  font-size: 0.875rem;
  letter-spacing: 0.35px;
  font-weight: 500;
  background-color: var.$newGreen;
  box-sizing: inherit;
  white-space: nowrap;

  &:hover {
    background-color: color.adjust($color: var.$newGreen, $lightness: -10%);
  }

  &:empty {
    display: none;
  }
}
