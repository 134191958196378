@use 'variables' as var;

@use 'mixins' as mix;

@use 'sass:string';

$primary: var.$black !default;
$font: var.$geist !default;

%input {
  width: 100%;
  font-size: 1rem;
  font-family: $font;
  letter-spacing: normal;
  background-color: var.$lightNeutralGray;
  border: 1px solid var.$lightNeutralGray;
  border-radius: var.$radius-sm;
  padding: 0.75rem 1rem;

  &::placeholder {
    font-size: 0.875rem;
  }

  &:focus,
  &:focus-within {
    outline: none;
    border-color: $primary;
  }
}

%input-clear {
  @extend %input;

  background-color: transparent;
  border-color: transparent;
}

%textarea {
  @extend %input;

  resize: vertical;
  white-space: pre-wrap;
}

%select {
  @extend %input;

  $primary-hex: string.slice('#{$primary}', 2);

  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="%23#{$primary-hex}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1rem;
  padding-right: 2rem;
}

%checkbox {
  [role='checkbox'][aria-checked='true'] {
    background-color: $primary;
    border-color: $primary;
  }
}

%label {
  font-family: var.$archivo-narrow;
  font-weight: 600;
  font-size: 0.75rem;
  letter-spacing: 0.15px;
  text-transform: uppercase;
}

%radio {
  [role='radio'][aria-checked='true'] {
    background-color: $primary;
    outline-color: $primary;
  }
}

%toggle {
  input:checked + label {
    [role='switch'] {
      background-color: $primary;
    }
  }
}

%autocomplete {
  &:focus-within {
    border-color: $primary;
  }

  [role='button'] {
    color: $primary;
  }
}

%helper-text {
  margin: 0.25rem 0 0;
  font-size: 0.75rem;
  color: var.$gray;
}

%error {
  margin: 0.25rem 0 0;
  font-size: 0.75rem;
  color: var.$negativeDay;
}
